// import logo from './logo.svg';
import './App.css';
import Todo from './component/Todo';

function App() {
  return (
    <Todo/>
  );
}

export default App;
