import firebase from "firebase";
var firebaseConfig = {
    apiKey: "AIzaSyAhBSNEZkoi1hRYR9HFpoXHwALpkAEoUTM",
    authDomain: "todolist-8dbc6.firebaseapp.com",
    projectId: "todolist-8dbc6",
    storageBucket: "todolist-8dbc6.appspot.com",
    messagingSenderId: "636346179094",
    appId: "1:636346179094:web:f718213605f0e42c3edf37"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export default firebase;