import React, { useState,useEffect } from "react";
import firebase from "../firebase";
import logo from '../images/work_black_24dp.svg';
import "../../node_modules/materialize-css/dist/css/materialize.min.css";
import "../../node_modules/materialize-css/dist/js/materialize.min";

const Todo = () => {
    const [inputData, setInputData] = useState('');
    const [items, setItem] = useState([]);
    const [todolist, settodolist] = useState([]);
    // const [itemcheck,setItemCheck]=useState('radio_button_unchecked');

    useEffect(()=>{
        const todoRef=firebase.database().ref("ToDoList");
        todoRef.on('value',(snapshot)=>{
            const todos=snapshot.val();
            const todolist=[];
            for(let id in todos){
                todolist.push({id,...todos[id]});
            }
            settodolist(todolist);
           // setItem(todolist);
        });
    },[]);
    const createTodo=()=>{
        const todoRef=firebase.database().ref("ToDoList");
        const task={
            task:inputData,
            isCompleted:false,
        }
        todoRef.push(task);
    }
    const addItem = () => {
        if (!inputData) {

        }
        else {
            setItem([...items, inputData]);
            setInputData('');
            createTodo();
        }

    }
    // const deleteTask = (id) => {
    //     //alert("delete task called"+ indx);
    //     const updatedTasks = items.filter((elem, indx) => {
    //         return indx !== id;
    //     });
    //     setItem(updatedTasks);

    // }
    const deleteTask=(id)=>{
        const todoRef=firebase.database().ref("ToDoList").child(id);
        todoRef.remove();
        //console.log(id);
    }
    const removeAll = () => {
        //setItem([]);
        const todoRef=firebase.database().ref("ToDoList");
        todoRef.removeAll();
    }
    const setDone=(id,status)=>{
        console.log("task done" +id);
        const todoRef=firebase.database().ref("ToDoList").child(id);
        todoRef.update({
            isCompleted:!status
        })
    }
    // const checkTask=()=>{

    //     if(itemcheck=="radio_button_unchecked"){
    //         setItemCheck('radio_button_checked');
    //     }
    //     else{
    //         setItemCheck('radio_button_unchecked');
    //     }

    // }
    return (<><div className="container">
        <div className="row">
            <div className="col s12">
                <center><img alt="logo" src={logo} /> <h4>Task Suite</h4><p>PLAN YOUR DAY!</p></center>
            </div>
        </div>
        <div className="row">
            <div className="col s12">
                <div className="input-field col s12">
                    <input type="text" id="todotask" placeholder="Add task" value={inputData} onChange={(e) => setInputData(e.target.value)} />
                    <i className="material-icons prefix" id="add_task" onClick={() => addItem()}>add_task</i>
                </div>
                {/* <div className="showtask">
                    {
                        items.map((elem, indx) => {
                            return (
                                <>
                                    <div className="eachtask input-group" >
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <td className="options">
                                                        <span className="material-icons" >task_alt</span>
                                                    </td>
                                                    <td scope="row" className="task">{elem}</td>
                                                    <td className="deleteTaskSection" >
                                                        <span className="material-icons remove_task" onClick={() => deleteTask(indx)}>remove_circle</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            );
                        })
                    }
                </div> */}

                <div className="showtask">
                    {
                        todolist.map((todo,index) => {
                            return (
                                <>
                                    <div className="eachtask input-group" >
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr >
                                                    <td className="options">
                                                    
                                                        <span className="material-icons" style={ todo.isCompleted==true?{"color":"green"}:{"color":"#3256a8"}} id={todo.id}
                                                         onClick={
                                                             () => setDone(todo.id,todo.isCompleted)
                                                        }>task_alt</span>
                                                    </td>
                                                
                                                    <td scope="row" className="task" style={ todo.isCompleted==true?{"color":"green"}:{"color":"#3256a8"}} >{index+1}  {todo.task}</td>
                                                    <td className="deleteTaskSection" >
                                                       
                                                        <span className="material-icons remove_task" onClick={() => deleteTask(todo.id)}>remove_circle</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            );
                        })
                    }
                </div>

                {/* <div style={{ "textAlign": "center" }}>
                    <button className="btn btn-danger"><span id="remove_all_btn" className="material-icons" onClick={() => removeAll()}>delete_forever</span></button>
                </div> */}

            </div>
        </div>
    </div></>);
};
export default Todo;